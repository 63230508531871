import { Box, spacing } from '@fortum/elemental-ui'

import type { FooterEntry } from '@/shared/contentful/types'
import { rejectNil } from '@/shared/utils/array'

import { CopyrightText } from './CopyrightText'
import { CountryMenu } from './CountryMenu'
import { LegalLinks } from './LegalLinks'

type Props = Pick<FooterEntry, 'legalLinksCollection' | 'countryLinksCollection'>

/**
 * Third row of the footer component.
 */
export const ThirdRow = (props: Props) => {
  const legalLinks = props.legalLinksCollection?.items
    ? rejectNil(props.legalLinksCollection?.items).filter((link) => link.page?.slug || link.url)
    : []

  return (
    <Box
      display="flex"
      flexDirection="column"
      pt={{ default: spacing.s, l: spacing.xs }}
      pb={spacing.xl}
      gap={{ default: spacing.xs, l: spacing.s }}
      data-testid="third-row"
    >
      <Box
        display="flex"
        flexDirection={{ default: 'column-reverse', s: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        gap={spacing.xs}
      >
        <CopyrightText />
        <CountryMenu />
      </Box>
      {legalLinks.length > 0 && <LegalLinks legalLinks={legalLinks} />}
    </Box>
  )
}
